// @flow

import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import { globalHistory } from "@reach/router";

import Link from '../MenuLink';
import Button from 'atomize/dist/atoms/button/Button';
import Row from 'atomize/dist/atoms/grid/Row';
import Col from 'atomize/dist/atoms/grid/Col';
//import Dropdown from 'atomize/dist/atoms/dropdown/Dropdown';
import Div from 'atomize/dist/atoms/div/Div';
import Text from 'atomize/dist/atoms/text/Text';

import {
  BiChevronDown as DownIcon
} from 'react-icons/bi';

import Dropdown from '../Dropdown';
import Spinner from '../Spinner';

import {
  getTotalProductStock, getProductFetchTime, isFetching, shouldFetch
} from '../../redux/selectors/productsSelectors';

import {
  getCartItemQty
} from '../../redux/selectors/cartSelectors';
import {fetchProduct} from '../../redux/actions/productsActions';

type Props = {
  +productId: string,
  +children?: any
}

export default function AddToCartBtn(props: Props) {

  const {productId, children, ...otherProps} = props;

  const dispatch = useDispatch();
  const [qty, setQty] = useState(1);
  const [isOpen, setOpen] = useState(false);

  const fetching = useSelector(state => isFetching(state, productId));
  const totalStock = useSelector(state => getTotalProductStock(state, productId));
  const cartItemCount = useSelector(state => getCartItemQty(state, productId));
  const fetchNeeded = useSelector(state => shouldFetch(state, productId));

  const remainingStock = totalStock - cartItemCount;

  // NOTE: Keeping it simple for now - querying every time AddToCartBtn appears. I might want to change that in the future.
  useEffect(() => {
    if (fetchNeeded) {
      dispatch(fetchProduct(productId));
    }
  }, [fetchNeeded]);

  if (fetching) {
    return <Spinner />
  }

  const itemCounts = remainingStock ? Array.from({length: remainingStock}, (_, i) => i + 1) : [];

  const Select = () => {


    // TODO: might want to extract it into a separate file and use elsewhere
    // Pretty much copied defaults from atomize's dropdown props
    // https://atomizecode.com/docs/react/molecules

    return (
      <>

      <Div className='add-to-cart-btn-select' tag='select' value={qty} w='6rem' rounded='circle' disabled={!remainingStock} m={{r: {xs: '0.5rem', sm: '1rem'}}} onChange={({target}) => setQty(Number(target.value))} p={{ x: "0.75rem" }} border='1px solid' borderColor='gray500' cursor='pointer' {...otherProps}>

        {
          itemCounts.map(cnt => {
            return (
              <Div tag='option' key={`AddToCartBtn-${productId}-option-${cnt}`}>{cnt}</Div>
            )
          })
        }
      </Div>
      </>
    );

  }


  return (
    <>
      <Div d='flex' style={totalStock ? {} : {display: 'none'}}>

        <Select />
        <Button disabled={!remainingStock} rounded='circle' w='100%' shadow="3" hoverShadow="4" bg='success700' hoverBg='success800' {...otherProps} onClick={() => {

            dispatch({
              type: 'UPDATE_CART_ITEM',
              payload: {
                id: productId,
                relQtyChange: qty,
                addTime: new Date().toISOString()
              }
            });
            dispatch({type: 'OPEN_CART', payload: {}});

            setQty(1);
          }}>
          {children}
        </Button>

      </Div>
      {
        !totalStock &&
        <Text textSize='caption' textColor='danger700'>Produkto šiuo metu neturime.</Text>
      }
      {
        // Roomie didn't like the error after adding an item
        // !!totalStock && !remainingStock &&
        // <Text textSize='caption' textColor='danger700'>Produktas jau yra krepšelyje ir daugiau jo šiuo metu neturime.</Text>
      }

    </>
  );


}
